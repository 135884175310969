.lds-dual-ring {
  display: inline-block;
}
.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 100px;
  height: 100px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid blue;
  border-color: blue transparent blue transparent;
  animation: lds-dual-ring 1.5s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
